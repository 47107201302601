<template>
    <div class="paywall-wrapper">
        <div class="paywall-body">
            <div class="paywall-left-side">
                <div class="paywall-avatar">
                    <CustomAvatar force-user-data :avatar-size="200" />
                    <img
                        src="/images/paywall/want-more.svg"
                        alt="want more"
                        class="paywall-want-more"
                    />
                </div>
                <span class="paywall-big-text">
                    Set
                    {{ playerFirstName }}
                    up
                    <span class="paywall-green-text">for future success</span>
                </span>
                <span class="paywall-text">
                    99math is a fun, teacher-approved platform to help your
                    child
                </span>
                <span class="paywall-text paywall-yellow-text">
                    boost foundational math skills and build confidence.
                </span>
                <div class="paywall-body-items">
                    <div class="paywall-body-item">
                        <img
                            src="/images/paywall/star.svg"
                            alt="star"
                            class="paywall-body-item-star"
                        />
                        <div class="paywall-body-item-container">
                            <span class="paywall-body-item-heading">
                                Extra play time
                            </span>
                            <span class="paywall-body-item-text">
                                Faster progress with balanced 15-20 minute
                                sessions
                            </span>
                        </div>
                    </div>
                    <div class="paywall-body-item">
                        <img
                            src="/images/paywall/star.svg"
                            alt="star"
                            class="paywall-body-item-star"
                        />
                        <div class="paywall-body-item-container">
                            <span class="paywall-body-item-heading">
                                Exciting rewards
                            </span>
                            <span class="paywall-body-item-text">
                                Personalized avatars to keep your child engaged
                            </span>
                        </div>
                    </div>
                    <div class="paywall-body-item">
                        <img
                            src="/images/paywall/star.svg"
                            alt="star"
                            class="paywall-body-item-star"
                        />
                        <div class="paywall-body-item-container">
                            <span class="paywall-body-item-heading">
                                Virtual math help
                            </span>
                            <span class="paywall-body-item-text">
                                Quick, clear explanations to help your child
                                learn new skills
                            </span>
                        </div>
                    </div>
                </div>
                <div class="paywall-trusted">
                    <img
                        src="/images/paywall/flag.svg"
                        alt="flag"
                        class="paywall-trusted-flag"
                    />
                    <span class="paywall-trusted-text">
                        TRUSTED BY 70,000+ TEACHERS IN THE U.S.
                    </span>
                </div>
                <div class="paywall-links">
                    <a
                        href="https://parents.99math.com/"
                        target="_blank"
                        rel="noopener"
                        class="paywall-links-read-more"
                    >
                        Read more
                    </a>
                    <span class="paywall-links-terms">
                        <a
                            :href="$getTermsOfUseLink"
                            target="_blank"
                            rel="noopener"
                        >
                            Terms and Conditions
                        </a>
                        and
                        <a
                            :href="$getPrivacyPolicyLink"
                            target="_blank"
                            rel="noopener"
                        >
                            Privacy Policy
                        </a>
                    </span>
                </div>
            </div>
            <div class="paywall-left-side-mobile">
                <span class="paywall-big-text">
                    Set
                    {{ playerFirstName }}
                    up
                    <br />
                    <span class="paywall-green-text">for future success</span>
                </span>
                <div class="paywall-trusted">
                    <img
                        src="/images/paywall/flag.svg"
                        alt="flag"
                        class="paywall-trusted-flag"
                    />
                    <span class="paywall-trusted-text">
                        TRUSTED BY 70,000+
                        <br />
                        TEACHERS IN THE U.S.
                    </span>
                </div>
            </div>
            <div class="paywall-right-side">
                <div class="paywall-card-wrapper">
                    <div class="paywall-card">
                        <img
                            src="/images/paywall/math-pass.svg"
                            alt="math pass"
                            class="paywall-math-pass"
                        />
                        <img
                            src="/images/paywall/math-pass-horizontal.svg"
                            alt="math pass"
                            class="paywall-math-pass-mobile"
                        />
                        <span class="paywall-card-heading">Math Pass</span>
                        <span class="paywall-card-subheading">
                            Premium account
                        </span>
                        <div class="paywall-card-price-container">
                            <span class="paywall-card-price">
                                {{
                                    `$${getPerMonth(selectedPriceOption)}${selectedPriceOption?.stripe?.recurring ? '/month' : ''}`
                                }}
                            </span>
                            <span class="paywall-card-price-annually">
                                {{ getCardPrice(selectedPriceOption) }}
                                {{ getBillingForPrice(selectedPriceOption) }}
                            </span>
                        </div>
                        <div class="paywall-checkboxes">
                            <div
                                v-for="priceItem in checkboxOptions"
                                :key="priceItem.stripe.id"
                                class="paywall-checkbox"
                                :class="{
                                    'paywall-checkbox__active':
                                        priceItem.stripe.id ===
                                        selectedPriceOption.stripe.id,
                                }"
                                @click="handleSelectPriceOption(priceItem)"
                            >
                                <img
                                    v-if="optionIsYearly(priceItem)"
                                    src="/images/paywall/save.svg"
                                    alt="save 50%"
                                    class="paywall-card-label"
                                />
                                <img
                                    v-if="optionIsLifetime(priceItem)"
                                    src="/images/paywall/best.svg"
                                    alt="best value"
                                    class="paywall-card-label"
                                />
                                <img
                                    v-if="optionIsYearly(priceItem)"
                                    src="/images/paywall/save-short.svg"
                                    alt="save 50%"
                                    class="paywall-card-label-mobile"
                                />
                                <img
                                    v-if="optionIsLifetime(priceItem)"
                                    src="/images/paywall/best-short.svg"
                                    alt="best value"
                                    class="paywall-card-label-mobile"
                                />
                                <span
                                    v-if="!optionIsLifetime(priceItem)"
                                    class="paywall-checkbox-price"
                                >
                                    {{
                                        `$${getPerMonth(priceItem)}${getShortHandForOption(priceItem)}`
                                    }}
                                </span>
                                <span
                                    v-if="optionIsLifetime(priceItem)"
                                    class="paywall-checkbox-price"
                                >
                                    {{
                                        `$${priceItem.stripe?.unit_amount / 100}`
                                    }}
                                </span>
                                <span class="paywall-checkbox-name">
                                    {{ getNameForPrice(priceItem) }}
                                </span>
                            </div>
                        </div>
                        <button
                            class="paywall-checkout-btn"
                            @click="handleNextStep"
                        >
                            Get Math Pass
                        </button>
                        <span class="paywall-checkout-cancelled">
                            {{
                                optionIsLifetime(selectedPriceOption)
                                    ? 'Subscription is paid for once.'
                                    : optionIsYearly(selectedPriceOption)
                                      ? 'Subscription renews yearly.'
                                      : 'Subscription renews monthly.'
                            }}
                            <span v-if="!optionIsLifetime(selectedPriceOption)">
                                (
                                <a
                                    href="http://legal.99math.com/terms-of-use#subscription"
                                >
                                    Cancel anytime
                                </a>
                                in settings )
                            </span>
                        </span>
                    </div>
                    <div v-if="user" class="paywall-card-footer">
                        <span>Purchase using another device</span>
                        <div
                            id="paywall-qr-code"
                            class="paywall-qr"
                            @click="handleSwitchQRCode"
                        />
                    </div>
                </div>
            </div>
            <div class="paywall-bottom-mobile">
                <span class="paywall-text">
                    99math is a fun, teacher-approved platform to help your
                    child
                    <span class="paywall-text paywall-yellow-text">
                        boost foundational math skills and build confidence.
                    </span>
                </span>
                <div class="paywall-body-items">
                    <div class="paywall-body-item">
                        <img
                            src="/images/paywall/star.svg"
                            alt="star"
                            class="paywall-body-item-star"
                        />
                        <div class="paywall-body-item-container">
                            <span class="paywall-body-item-heading">
                                Extra play time
                            </span>
                            <span class="paywall-body-item-text">
                                Faster progress with balanced 15-20 minute
                                sessions
                            </span>
                        </div>
                    </div>
                    <div class="paywall-body-item">
                        <img
                            src="/images/paywall/star.svg"
                            alt="star"
                            class="paywall-body-item-star"
                        />
                        <div class="paywall-body-item-container">
                            <span class="paywall-body-item-heading">
                                Exciting rewards
                            </span>
                            <span class="paywall-body-item-text">
                                Personalized avatars to keep your child engaged
                            </span>
                        </div>
                    </div>
                    <div class="paywall-body-item">
                        <img
                            src="/images/paywall/star.svg"
                            alt="star"
                            class="paywall-body-item-star"
                        />
                        <div class="paywall-body-item-container">
                            <span class="paywall-body-item-heading">
                                Virtual math help
                            </span>
                            <span class="paywall-body-item-text">
                                Quick, clear explanations to help your child
                                learn new skills
                            </span>
                        </div>
                    </div>
                </div>
                <div class="paywall-links">
                    <a
                        href="https://parents.99math.com/"
                        target="_blank"
                        rel="noopener"
                        class="paywall-links-read-more"
                    >
                        Read more
                    </a>
                    <span class="paywall-links-terms">
                        <a
                            :href="$getTermsOfUseLink"
                            target="_blank"
                            rel="noopener"
                        >
                            Terms and Conditions
                        </a>
                        and
                        <a
                            :href="$getPrivacyPolicyLink"
                            target="_blank"
                            rel="noopener"
                        >
                            Privacy Policy
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomAvatar from '@/core/components/Avatar/CustomAvatar.vue';
import { mapGetters } from 'vuex';
import { isParent } from '@/flows/Authentication/helpers/AuthHelpers.js';
import moment from 'moment';
import QRCodeStyling from 'qr-code-styling';

export default {
    components: {
        CustomAvatar,
    },
    props: {
        unauthorizedAccount: Boolean,
        unauthorizedUserName: String,
        lastStudentClass: Object,
        priceTestVersion: Array,
        discount: Object,
        teacherInfo: Object,
        handleSwitchQRCode: Function,
    },
    emits: ['nextStep', 'selectedPlanChange'],
    data() {
        return {
            selectedPriceOption: null,
        };
    },
    computed: {
        ...mapGetters({
            user: 'user',
            currentLanguage: 'getCurrentLanguage',
            priceTest: 'getUsersPriceTest',
        }),
        ...mapGetters(['getReferral', 'getTeachingGrades']),
        isFacebookSignup() {
            return this.user?.flags?.parentSignupFacebook;
        },
        isParent() {
            return isParent(this.user);
        },
        playerFirstName() {
            return this.unauthorizedAccount
                ? this.unauthorizedUserName
                : this.user?.firstName;
        },
        checkboxOptions() {
            if (this.isFacebookSignup) {
                return (
                    this.priceTest?.products ||
                    this.priceTestVersion?.products ||
                    []
                ).filter((po) => !this.optionIsLifetime(po));
            }
            return (
                this.priceTest?.products ||
                this.priceTestVersion?.products ||
                []
            );
        },
        priceTestAndPriceTestVersion() {
            return this.priceTest || this.priceTestVersion;
        },
        discountAmount() {
            return (
                moment(this.discount?.redeem_by * 1000).isAfter(moment()) *
                    this.discount?.percent_off *
                    0.01 || 1
            );
        },
    },
    watch: {
        priceTestAndPriceTestVersion: {
            immediate: true,
            handler: function (newVal) {
                if (newVal?.products?.length) {
                    if (this.discount) {
                        const monthlyOption = newVal.products.find(
                            (item) =>
                                item?.stripe?.recurring?.interval === 'month' &&
                                item?.stripe?.recurring?.interval_count === 1,
                        );

                        if (monthlyOption) {
                            this.selectedPriceOption = monthlyOption;
                        }
                    } else {
                        const yearlyOption = newVal.products.find(
                            (item) =>
                                item?.stripe?.recurring?.interval === 'year',
                        );

                        if (yearlyOption) {
                            this.selectedPriceOption = yearlyOption;
                        }
                    }
                }
            },
        },
        selectedPriceOption: {
            immediate: true,
            handler: function (newVal) {
                this.$emit('selectedPlanChange', newVal);
            },
        },
    },
    mounted() {
        this.createQRCode();
    },
    methods: {
        createQRCode() {
            console.log(
                `${window.location.protocol}//${window.location.host}/buy-premium/${this.user?.userId}`,
            );

            const qrCode = new QRCodeStyling({
                width: 100,
                height: 100,
                type: 'canvas',
                data: `${window.location.protocol}//${window.location.host}/buy-premium/${this.user?.userId}`,
                image: '/images/lobby/99math-logo-blue.svg',
                dotsOptions: {
                    color: 'black',
                    type: 'rounded',
                },
                imageOptions: {
                    margin: 0,
                },
            });

            qrCode.append(document.getElementById('paywall-qr-code'));
        },
        handleSelectPriceOption(priceOption) {
            this.selectedPriceOption = priceOption;
        },
        handleNextStep() {
            this.$emit('nextStep');
        },
        getCardPrice(priceItem) {
            if (!priceItem) return;

            return this.optionIsLifetime(priceItem) ||
                this.optionIsYearly(priceItem)
                ? `$${priceItem.stripe?.unit_amount / 100}`
                : `$${this.getPerMonth(priceItem, this.discountAmount)}`;
        },
        getDiscountForPrice(priceItem) {
            if (!priceItem) return;

            return this.optionIsLifetime(priceItem)
                ? '$399.99'
                : this.optionIsYearly(priceItem)
                  ? '$120.00'
                  : this.discount
                    ? `$${this.getPerMonth(priceItem)}`
                    : '';
        },

        optionIsWeekly(priceItem) {
            return priceItem?.stripe?.recurring?.interval === 'week';
        },
        optionIsYearly(priceItem) {
            return priceItem?.stripe?.recurring?.interval === 'year';
        },
        optionIsLifetime(priceItem) {
            return !!priceItem?.stripe?.metadata?.lifetimeSubscription;
        },
        optionIsMonthly(priceItem) {
            return priceItem?.stripe?.recurring?.interval === 'month';
        },
        getPerMonth(priceItem, discount = 1) {
            if (!priceItem) return;

            if (!priceItem.stripe.recurring) {
                return ((priceItem.stripe.unit_amount * discount) / 100)
                    .toString()
                    .match(/^\d+(?:\.\d{0,2})?/)[0];
            }

            const months =
                priceItem.stripe.recurring?.interval === 'year'
                    ? priceItem.stripe.recurring.interval_count * 12
                    : priceItem.stripe.recurring?.interval_count;

            return ((priceItem.stripe.unit_amount * discount) / 100 / months)
                .toString()
                .match(/^\d+(?:\.\d{0,2})?/)[0];
        },
        getBillingForPrice(priceItem) {
            if (this.optionIsLifetime(priceItem)) {
                return 'billed once';
            }

            if (this.optionIsYearly(priceItem)) {
                return this.isFacebookSignup
                    ? 'billed in 7 days'
                    : `billed now`;
            }

            if (this.optionIsWeekly(priceItem)) {
                return this.isFacebookSignup
                    ? 'billed in 7 days'
                    : 'billed weekly';
            }

            if (this.optionIsMonthly(priceItem) && this.discount) {
                return `First month & $${this.getPerMonth(
                    priceItem,
                )} thereafter`;
            }

            if (this.optionIsMonthly(priceItem)) {
                return 'billed monthly';
            }

            return `billed $${this.getPerMonth(priceItem)} / month`;
        },
        getShortHandForOption(priceItem) {
            const recurring = priceItem?.stripe?.recurring;

            if (recurring?.interval === 'week') {
                return '/week';
            }
            if (recurring?.interval === 'month') {
                if (recurring?.interval_count === 1) {
                    return '/m';
                }

                if (recurring?.interval_count === 6) {
                    return '/half-a-year';
                }
            }

            if (recurring?.interval === 'year') {
                return '/m';
            }

            if (priceItem?.stripe?.metadata?.lifetimeSubscription) {
                return 'Once';
            }
        },
        getNameForPrice(priceItem) {
            const recurring = priceItem?.stripe?.recurring;

            if (recurring?.interval === 'week') {
                return 'Weekly';
            }
            if (recurring?.interval === 'month') {
                if (recurring?.interval_count === 1) {
                    return 'Monthly';
                }

                if (recurring?.interval_count === 6) {
                    return 'Half Year';
                }
            }

            if (recurring?.interval === 'year') {
                return 'Yearly';
            }

            if (priceItem?.stripe?.metadata?.lifetimeSubscription) {
                return 'Lifetime';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.paywall-wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
    width: 100dvw;
    min-height: 100vh;
    min-height: 100dvh;
    background: linear-gradient(173deg, #572598 0%, #6e14a7 133.55%);

    @media screen and (width: 1366px) and (height: 1024px) {
        justify-content: center;
    }

    @media screen and (width: 1180px) and (height: 820px) {
        justify-content: center;
    }

    @media screen and (width: 1024px) and (height: 768px) {
        justify-content: center;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        min-height: unset;
    }

    @media screen and (max-width: $w-max-phone) {
        min-height: unset;
        width: 100%;
    }
}
.paywall-body {
    display: flex;
    width: 100%;

    @media screen and (max-width: $w-max-phone) {
        flex-direction: column;
    }
}
.paywall-left-side {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-left: 100px;
    transform: translateY(-90px);

    @media screen and (max-height: 1080px) {
        padding-left: 50px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        padding-left: 30px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        padding-left: 10px;
        transform: translateY(-50px);
    }

    @media screen and (max-width: $w-max-phone) {
        display: none;
    }
}
.paywall-right-side {
    display: flex;
    width: 40%;

    @media screen and (max-height: $h-max-under-chromebook) {
        transform: translateY(-30px);
    }

    @media screen and (max-width: $w-max-phone) {
        width: 100%;
        height: 100%;
        transform: unset;
    }
}
.paywall-left-side-mobile {
    display: none;

    @media screen and (max-width: $w-max-phone) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.paywall-bottom-mobile {
    display: none;

    @media screen and (max-width: $w-max-phone) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 120px;
    }
}
.paywall-avatar {
    position: relative;
    display: flex;
    align-self: center;

    :deep(.c-avatar__container) {
        @media screen and (max-height: 1080px) {
            width: 170px !important;
            min-width: 170px !important;
            height: 170px !important;
            min-height: 170px !important;
        }

        @media screen and (max-width: $w-max-lg-chromebook) {
            width: 140px !important;
            min-width: 140px !important;
            height: 140px !important;
            min-height: 140px !important;
        }

        @media screen and (max-height: $h-max-under-chromebook) {
            width: 80px !important;
            min-width: 80px !important;
            height: 80px !important;
            min-height: 80px !important;
        }
    }
}
.paywall-want-more {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-85%, 10%);

    @media screen and (max-height: 1080px) {
        width: 170px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        width: 150px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        width: 90px;
    }
}
.paywall-big-text {
    margin: 34px 0 19px;
    color: #fff;
    font-family: $secondary-font-family;
    font-size: 64px;
    font-style: italic;
    font-weight: 900;

    @media screen and (max-height: 1080px) {
        margin: 20px 0;
        font-size: 50px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        margin: 10px 0;
        font-size: 40px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        margin: 6px 0;
        font-size: 16px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 40px;
        text-align: center;
    }
}
.paywall-green-text {
    color: #2be47f;
}
.paywall-text {
    color: #fff;
    font-family: $secondary-font-family;
    font-size: 26px;
    font-style: italic;
    font-weight: 500;

    @media screen and (max-height: 1080px) {
        font-size: 24px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        font-size: 18px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 12px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 24px;
        text-align: center;
    }
}
.paywall-yellow-text {
    color: #f7d740;
}
.paywall-body-items {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin: 57px 0 83px;

    @media screen and (max-height: 1080px) {
        gap: 20px;
        margin: 20px 0;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        gap: 20px;
        margin: 10px 0;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        gap: 8px;
        margin: 6px 0;
    }

    @media screen and (max-width: $w-max-phone) {
        gap: 30px;
        margin: 46px 0 30px;
    }
}
.paywall-body-item {
    display: flex;
    gap: 16px;

    @media screen and (max-width: $w-max-lg-chromebook) {
        gap: 10px;
    }

    @media screen and (max-width: $w-max-phone) {
        flex-direction: column;
        align-items: center;
    }
}
.paywall-body-item-star {
    @media screen and (max-height: 1080px) {
        width: 40px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        width: 30px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        width: 16px;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 45px;
    }
}
.paywall-body-item-container {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $w-max-phone) {
        align-items: center;
    }
}
.paywall-body-item-heading {
    color: #fff;
    font-family: $secondary-font-family;
    font-size: 32px;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;

    @media screen and (max-height: 1080px) {
        font-size: 24px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        font-size: 22px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 12px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 26px;
    }
}
.paywall-body-item-text {
    color: #fff;
    font-family: $secondary-font-family;
    font-size: 26px;
    font-style: italic;
    font-weight: 500;

    @media screen and (max-height: 1080px) {
        font-size: 20px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        font-size: 18px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 11px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 24px;
        text-align: center;
    }
}
.paywall-trusted {
    display: flex;
    align-items: center;

    @media screen and (max-width: $w-max-phone) {
        justify-content: center;
    }
}
.paywall-trusted-flag {
    z-index: 1;

    @media screen and (max-height: 1080px) {
        width: 60px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        width: 50px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        width: 24px;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 75px;
    }
}
.paywall-trusted-text {
    padding: 0 12px 0 24px;
    border-radius: 16px;
    background: #4d198e;
    transform: translateX(-12px);
    z-index: 0;
    color: #fff;
    font-family: $secondary-font-family;
    font-size: 26px;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;

    @media screen and (max-height: 1080px) {
        font-size: 22px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        font-size: 20px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 12px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 22px;
        border-radius: 6px;
    }
}
.paywall-links {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 36px;

    @media screen and (max-height: 1080px) {
        margin-top: 20px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        margin-top: 16px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        gap: 10px;
        margin-top: 6px;
    }

    @media screen and (max-width: $w-max-phone) {
        flex-direction: column;
        gap: 33px;
        margin: 0 0 30px;
    }
}
.paywall-links-read-more {
    color: #fff;
    font-family: $secondary-font-family;
    font-size: 24px;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration-line: underline;

    @media screen and (max-width: $w-max-lg-chromebook) {
        font-size: 20px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 14px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 24px;
    }
}
.paywall-links-terms {
    color: #fff;
    font-family: $secondary-font-family;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;

    @media screen and (max-width: $w-max-lg-chromebook) {
        font-size: 16px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 12px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 18px;
    }

    a {
        color: #fff;
        text-decoration-line: underline;
    }
}
.paywall-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: $w-max-phone) {
        width: 100%;
        margin-top: 43px;
    }
}
.paywall-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 560px;
    height: 766px;
    margin-top: 20px;
    padding: 37px 25px 12px;
    border-radius: 24px;
    background: #fff;
    z-index: 2;

    @media screen and (max-height: 1080px) {
        width: 500px;
        height: 520px;
        margin-top: 10px;
        padding: 20px 20px 10px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        width: 400px;
        height: 400px;
        margin-top: 10px;
        padding: 15px 15px 10px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        width: 95%;
        height: 230px;
        padding: 6px 10px;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 100%;
        height: 100%;
    }
}
.paywall-math-pass {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-30%, -40%);

    @media screen and (max-height: 1080px) {
        width: 170px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        width: 150px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        width: 80px;
        transform: translate(-25%, -25%);
    }

    @media screen and (max-width: $w-max-phone) {
        display: none;
    }
}
.paywall-math-pass-mobile {
    display: none;

    @media screen and (max-width: $w-max-phone) {
        position: absolute;
        top: 0;
        left: 50%;
        display: flex;
        transform: translate(-50%, -60%);
    }
}
.paywall-card-heading {
    color: #000;
    text-align: center;
    font-family: $secondary-font-family;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;

    @media screen and (max-height: 1080px) {
        font-size: 40px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        font-size: 30px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 16px;
    }

    @media screen and (max-width: $w-max-phone) {
        margin-top: 30px;
        font-size: 48px;
    }
}
.paywall-card-subheading {
    padding: 0 24px;
    background: #f7d740;
    color: #8c5903;
    text-align: center;
    font-family: $secondary-font-family;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    text-transform: uppercase;

    @media screen and (max-height: 1080px) {
        padding: 0 10px;
        font-size: 20px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        padding: 0 10px;
        font-size: 16px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        padding: 0 8px;
        font-size: 12px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 22px;
    }
}
.paywall-card-price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 125px 0 150px;

    @media screen and (max-height: 1080px) {
        margin: 40px 0 70px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        margin: 20px 0 60px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        margin: 6px 0 30px;
    }

    @media screen and (max-width: $w-max-phone) {
        margin: 70px 0 80px;
    }
}
.paywall-card-price {
    color: #000;
    text-align: center;
    font-family: $secondary-font-family;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;

    @media screen and (max-height: 1080px) {
        font-size: 40px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        font-size: 30px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 16px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 48px;
    }
}
.paywall-card-price-annually {
    color: #404040;
    text-align: center;
    font-family: $secondary-font-family;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;

    @media screen and (max-height: 1080px) {
        font-size: 20px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        font-size: 16px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 12px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 24px;
    }
}
.paywall-checkboxes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 17px;

    @media screen and (max-height: 1080px) {
        gap: 14px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        gap: 10px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        gap: 6px;
    }

    @media screen and (max-width: $w-max-phone) {
        flex-direction: column;
        width: 100%;
    }
}
.paywall-checkbox {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 162px;
    height: 133px;
    padding: 5px 5px 15px;
    border-radius: 8px;
    background: #ededed;
    cursor: pointer;

    @media screen and (max-height: 1080px) {
        width: 140px;
        height: 120px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        width: 120px;
        height: 90px;
        padding: 5px 5px 10px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        width: 80px;
        height: 60px;
        padding: 5px 5px;
    }

    @media screen and (max-width: $w-max-phone) {
        flex-direction: row-reverse;
        justify-content: start;
        width: 100%;
    }

    &__active {
        background: #2be47f;
    }
}
.paywall-checkbox-price {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 153px;
    height: 72px;
    background: #fff;
    border-radius: 4px;
    color: #404040;
    text-align: center;
    font-family: $secondary-font-family;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;

    @media screen and (max-height: 1080px) {
        width: 100%;
        height: 60px;
        font-size: 26px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        width: 100%;
        height: 50px;
        font-size: 22px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        height: 30px;
        font-size: 14px;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 152px;
        height: 60px;
    }
}
.paywall-checkbox-name {
    color: #083d20;
    text-align: center;
    font-family: $secondary-font-family;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;

    @media screen and (max-height: 1080px) {
        font-size: 22px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        font-size: 18px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 14px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 24px;
        width: 120px;
    }
}
.paywall-checkout-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 85px;
    margin: 18px 0 10px;
    background: #2be47f;
    border: unset;
    border-radius: 8px;
    color: #083d20;
    text-align: center;
    font-family: $secondary-font-family;
    font-size: 42px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    cursor: pointer;

    @media screen and (max-height: 1080px) {
        height: 70px;
        font-size: 40px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        height: 60px;
        margin: 10px 0;
        font-size: 36px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        height: 30px;
        margin: 6px 0 4px;
        font-size: 18px;
    }

    @media screen and (max-width: $w-max-phone) {
        height: 85px;
        margin: 20px 0 14px;
        font-size: 42px;
    }
}
.paywall-checkout-cancelled {
    color: #a3a3a3;
    text-align: center;
    font-family: $secondary-font-family;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;

    @media screen and (max-height: 1080px) {
        font-size: 16px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        font-size: 14px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 10px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 18px;
    }
}
.paywall-card-label {
    position: absolute;
    top: 0;
    transform: translateY(-120%);

    @media screen and (max-height: 1080px) {
        width: 140px;
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        width: 120px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        width: 70px;
    }

    @media screen and (max-width: $w-max-phone) {
        display: none;
    }
}
.paywall-card-label-mobile {
    display: none;

    @media screen and (max-width: $w-max-phone) {
        display: flex;
    }
}
.paywall-card-footer {
    position: relative;
    display: flex;
    align-items: end;
    width: 100%;
    height: 70px;
    transform: translateY(-20px);
    padding: 0 0 6px 28px;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    background: #f7d740;
    z-index: 1;

    @media screen and (max-width: $w-max-lg-chromebook) {
        height: 50px;
        transform: translateY(-20px);
        padding: 0 0 4px 14px;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        width: 95%;
        height: 40px;
        transform: translateY(-20px);
        padding: 0 0 1px 10px;
        border-bottom-right-radius: 14px;
        border-bottom-left-radius: 14px;
    }

    @media screen and (max-width: $w-max-phone) {
        justify-content: center;
        width: 100%;
        height: 100px;
        padding: 0 0 34px;
    }

    span {
        color: #8c5903;
        font-family: $secondary-font-family;
        font-size: 28px;
        font-style: italic;
        font-weight: 900;
        line-height: 120%;

        @media screen and (max-height: 1080px) {
            font-size: 24px;
        }

        @media screen and (max-width: $w-max-lg-chromebook) {
            font-size: 18px;
        }

        @media screen and (max-height: $h-max-under-chromebook) {
            font-size: 14px;
        }

        @media screen and (max-width: $w-max-phone) {
            font-size: 24px;
        }
    }
}
.paywall-qr {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(75px);
    background: #f7d740;
    border-radius: 24px;
    padding: 10px;
    cursor: pointer;

    @media screen and (max-width: $w-max-lg-chromebook) {
        transform: translateY(40px);
        border-radius: 14px;
        padding: 8px;

        :deep(canvas) {
            width: 50px;
        }
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        transform: translateY(22px);
        border-radius: 6px;
        padding: 4px;

        :deep(canvas) {
            width: 30px;
        }
    }

    @media screen and (max-width: $w-max-phone) {
        left: 50%;
        right: unset;
        transform: translate(-50%, 100px);
        border-radius: 10px;
        padding: 10px;

        :deep(canvas) {
            width: 111px;
        }
    }
}
</style>
