<template>
    <div class="bqrm-wrapper">
        <div class="bqrm-container">
            <img
                src="/images/paywall/close.svg"
                alt="close"
                class="brqm-close"
                @click="close"
            />
            <span class="brqm-heading">Purchase 99math</span>
            <span class="brqm-heading">using another device</span>
            <div id="brqm-code" class="bqrm-code" />
        </div>
    </div>
</template>
<script>
import QRCodeStyling from 'qr-code-styling';

export default {
    props: {
        link: String,
        close: Function,
    },
    mounted() {
        const qrCode = new QRCodeStyling({
            width: 715,
            height: 715,
            type: 'canvas',
            data: this.link,
            image: '/images/lobby/99math-logo-blue.svg',
            dotsOptions: {
                color: 'black',
                type: 'rounded',
            },
        });

        qrCode.append(document.getElementById('brqm-code'));
    },
};
</script>
<style lang="scss" scoped>
.bqrm-wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 100002;
    background: rgba(0, 0, 0, 0.8);
}
.bqrm-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
    height: 868px;
    padding: 13px 0;
    border-radius: 40px;
    background: #611e9f;

    @media screen and (max-height: 1080px) {
        width: 60%;
        height: 80%;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 95%;
        height: 70%;
    }
}
.brqm-heading {
    color: #fff;
    text-align: center;
    font-family: $secondary-font-family;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 113%; /* 45.2px */

    @media screen and (max-height: $h-max-under-chromebook) {
        font-size: 20px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 30px;
    }
}
.brqm-close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 50%);
    cursor: pointer;

    @media screen and (max-height: $h-max-under-chromebook) {
        width: 30px;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 30px;
    }
}
.bqrm-code {
    margin-top: 12px;

    @media screen and (max-height: 1080px) {
        margin: auto;

        :deep(canvas) {
            height: 500px;
        }
    }

    @media screen and (max-width: $w-max-lg-chromebook) {
        :deep(canvas) {
            height: 300px;
        }
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        :deep(canvas) {
            height: 150px;
        }
    }
}
</style>
